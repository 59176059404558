@import "styles/colors.scss";
@import "styles/mixins.scss";

.specialist-box {
   @include box;

   display: flex;
   align-items: flex-start;
   gap: 30px;
   margin-bottom: 30px;

   .user-avatar {
      flex: 0 0 220px;

      img {
         width: 220px;
         height: 220px;
      }
   }
}

.specialist-box-main {
   flex: auto;
}

.specialist-box-bottom {
   margin-top: 16px;
   padding-top: 16px;
   border-top: 1px solid $line;

   display: flex;
   flex-wrap: wrap;
   align-items: flex-start;
   gap: 30px;

   h3 {
      margin-top: 0;
   }

   a:not(.button) {
      display: flex;
      align-items: center;
      gap: 8px;

      color: $text;
      margin-bottom: 16px;

      svg { fill: $red; }
   }

   > div:first-child {
      flex: 0 0 400px;
   }
}


@media (max-width: 1330px) {
   .specialist-box {
      padding: 15px;
   }
}

@media (max-width: 1100px) {

   .specialist-box {
      gap: 16px;
   }

   .specialist-box-bottom {
      > div { flex: 1 1 100% !important; }
   }
}


@media (max-width: 800px) {
   .specialist-box {
      flex-direction: column;

      .user-avatar {
         margin: auto;
      }
   }
}