@import "styles/colors.scss";

.breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;

    font-size: 0.875em;
    font-weight: 600;

    span, a {
        display: flex;
        align-items: center;
        gap: 3px;    
    }

    svg.stroke {
        stroke: $purple;
    }
}