@import "styles/colors.scss";

.tooltip {
    cursor: pointer;
    position: relative;
    display: flex;
}

.tooltip-text {
    position: absolute;
    z-index: 1205;
    padding: 22px;

    background: $tooltip_bg;
    border: 1px solid $red;
    border-radius: 3px;

    ul {
        padding-left: 20px;
    }
}