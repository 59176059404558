@import "styles/colors.scss";
@import "styles/mixins.scss";

.points-summary {
   padding-top: 12px;
   padding-bottom: 12px;
   
   .box-body {
      display: flex;
      align-items: center;
      gap: 16px;

      h2 { margin: 0; }

      span {
         @include circle( 77px, #EEFFF2, true );
         font-size: 1.75em;
         font-weight: bold;
         color: $green;
         border: 2px solid $green;
      }

      @media (max-width: 500px) {
         flex-direction: column;
      }
   }
}