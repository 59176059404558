@import "styles/colors.scss";

.calendar {
    width: 388px;
    background: $white;
    padding: 12px 25px;

    border: 1px solid $red;
    border-radius: 4px;

    @media (max-width: 450px) {
      width: 300px;
      padding: 10px 15px;
    }
}

.calendar-head {
    display: flex;
    align-items: center;

    margin-bottom: 10px;
    font-weight: 500;

    button {
        cursor: pointer;
        border: 0;
        padding: 0;
        margin: 0;
        background: transparent;
    }

    svg { stroke: $gray3; }

    span {
        text-align: center;
        flex: auto;
    }
}

.calendar-body {
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    > div {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        
        color: $gray3;
        aspect-ratio: 1;

        &.available {
            font-weight: 600;
            color: $green;
        }

        &.not-available {
            opacity: .4;
        }

        &.selected {
            background: $green;
            color: $white;
            font-weight: 600;
        }
    }
}