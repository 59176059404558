@import "styles/colors.scss";
@import "styles/mixins.scss";

.conversation-message {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 6px;
    font-size: 14px;

    span {
        min-width: 46px;
    }

    .delete {
        display: none;              
        flex-direction: row-reverse;  

        .delete-icon {
            cursor: pointer;
            display: block;
            margin-right: 5px;
            padding: 5px;            
            width: 30px;
            height: 30px;
            border-radius: 30px;
        }

        .delete-date {
            display: block;
            line-height: 32.5px;
        }
    }        
}

.conversation-message:hover {
    .delete {
        display: flex;
        flex-direction: row;  

        .delete-icon:hover {
            background: $gray2;
        }
    }
}

.conversation-message.reverse {
    flex-direction: row-reverse;

    .conversation-message-content {
        background: $gray2;
        color: $text;        
        border-radius: 2px 12px 12px 2px;
    }

    .conversation-message-attachments {
        align-items: flex-start;
    }

    .conversation-message-attach {
        background: $gray2;
        color: $text;        
    }
}

.conversation-message-content {

    border-radius: 12px 2px 2px 12px;
    padding: 10px 16px;
    max-width: 400px;

    background: $purple;
    color: $white;
}

.conversation-message-attachments {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-end;
    width: 100%;

    img {
        margin-bottom: -6px;
        width: 400px;
        height: 250px;
        object-fit: cover;
        border-radius: 6px;
    }
}

.conversation-message-attach {

    cursor: pointer;

    display: flex;
    align-items: center;
    gap: 8px;

    border-radius: 12px 12px 2px 12px;
    background: $purple;
    color: $white;

    font-weight: 600;

    padding: 6px 16px;

    > div {
        @include circle( 48px, $white, true );

        svg { fill: $red };
    }
}