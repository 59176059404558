@import "styles/colors.scss";
@import "styles/mixins.scss";

.documents-repository {
    display: flex;
    gap: 20px;

    @media (max-width: 1100px) {
      display: block;
    }
}

.documents-repository-sidebar {
    @include box;
    flex: 0 0 263px;
    padding-left: 20px;
    padding-right: 20px; 
    
    @media (max-width: 1100px) {
      padding: 15px;
      margin-bottom: 10px;
    }
}

.documents-repository-sidebar-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    h2 { margin: 0; }
}

.documents-repository-sidebar-main {

    .directory {
        display: flex;
        align-items: center;
        gap: 6px;

        span { cursor: pointer; }
    }
}

.documents-repository-box {
    @include box;
    flex: auto;

    .breadcrumbs {
        margin-bottom: 24px;

        span { cursor: pointer; }
    }

    @media (max-width: 1100px) {
      padding: 15px;
      margin-bottom: 10px;
    }
}

.documents-repository-box-head {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    gap: 10px;

    h2 { margin: 0; }

    @media (max-width: 475px) {
      flex-wrap: wrap;
      h2 { width: 100%;}
    }
}

.documents-repository-box-filters {
    display: flex;
    align-items: center;
    gap: 20px;

    .form-input { flex: auto; }

    .form-dropdown { flex: 0 0 210px; }

    @media (max-width: 1100px) {
      display: block;
    }
}

.documents-repository-box-main {

    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(156px, calc( 100% / 6 - 17px ) ));
    gap: 20px;

    .item-tile {
        cursor: pointer;
        position: relative;
    }
    
    .item-tile-main {

        position: relative;

        .checkbox {
            position: absolute;
            right: 2px;
            top: 2px;
            margin: 0;

            background: $white;
            padding: 4px;
            border-radius: 4px;
        }

        > div:not( .checkbox ) {
            @include flex-centered;
            background: $gray;

            svg {
                width: 60px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            aspect-ratio: 1;
            border: 1px solid $red;
            border-radius: 3px;
        }

        > span {
            user-select: none;
            margin-top: 14px;
            display: block;
            text-align: center;
        }
    }

    .item-tile-popup {
        position: absolute;
        z-index: 99;
        left: 50% !important;
        top: 50% !important;

        background-color: $tooltip_bg;

        width: 204px;
        padding: 0 16px;
        border: 1px solid $red;

        > div {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 20px;
            padding: 14px 0;

            border-bottom: 1px solid $red;

            &:last-child { border: 0; }
        }
    }

    @media (max-width: 600px) {
      grid-template-columns: repeat(2, 1fr);

      .item-tile-popup {
         width: 145px;
         padding: 0 8px;
         left: 10px !important;
         top: 10px !important;

         > div {
            padding: 8px 0;
         }
      }
    }

    @media (max-width: 374px) {
      grid-template-columns: 1fr;
    }
}