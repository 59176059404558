@import "styles/colors.scss";

.review-box {
   padding: 30px 0;
   border-bottom: 1px solid $line;

   &:first-child {
      padding-top: 0;
   }

   &:last-child {
      border: 0;
      padding-bottom: 0;
   }
}

.review-box-main {
   > div {
      margin: 8px 0;
   }
}

.review-box-answer {
   margin-top: 20px;
   padding: 30px;
   background: $tooltip_bg;
   border-radius: 6px;
}