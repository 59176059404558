@import "styles/colors.scss";
@import "styles/mixins.scss";

.pagination {
	@include flex_centered;
	margin-top: 30px;
	gap: 10px;

	font-size: 1em;
	font-weight: 700;

	span {
		width: 11px;
		text-align: center;
	}

	button {

		font-family: "Dosis", sans-serif;
		font-size: 1em;
		font-weight: 700;

		cursor: pointer;
		user-select: none;
		text-align: center;

		width: 48px;
		height: 48px;
		border-radius: 4px;

		background: $gray2;
		padding: 0;
		border: 0;
	}

	button.next,
	button.prev {
		display: flex;
		background: transparent;
		
		svg {
			margin: auto;
			stroke: #737373;
		}
	}

	button[disabled] {
		opacity: 0.3;
	}

	button.active {
		color: $white;
		background: $red;
	}

   @media (max-width: 1100px ) {
      gap: 6px;
      margin-top: 20px;

      button {
         width: 42px;
         height: 42px;
      }
   }
}