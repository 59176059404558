@import "styles/colors.scss";

.loader-wrap {
	display: flex;
	width: 100%;
	height: 60px;
}

.loader {
	display: block;
	position: relative;
	width: 50px;
	height: 50px;
	margin: auto;

	div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 40px;
		height: 40px;
		margin: 4px;
		border: 4px solid $purple;
		border-radius: 50%;
		animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: $purple transparent transparent transparent;

		&:nth-child(1) {
			animation-delay: -0.45s;
		}

		&:nth-child(2) {
			animation-delay: -0.3s;
		}

		&:nth-child(3) {
			animation-delay: -0.15s;
		}
	}
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}