.documents-repo-modal {
    .modal-box { 
        width: calc( 100vw - 30px );
    }
    
    .documents-repository {
        height: calc( 100vh - 240px );
    }

    @media (max-width: 1100px ) {
      .documents-repository {
         height: auto;
      }

      .documents-repository-sidebar {
         padding: 0;
         margin-bottom: 20px;
      }

      .documents-repository-box {
         padding: 0;
      }
    }
}