@import "styles/colors.scss";

.not-logged-in {
	min-height: calc( var(--vh, 1vh ) * 100 );

	display: flex;
	flex-direction: column;
   padding: 15px;

	.box {
		background: $white;
		margin: auto;
		width: 764px;
		padding: 40px 188px;
		border-radius: 3px;

		h1 {
			text-align: center;
			margin: 30px 0;
		}
	}

   .box-logo {
      max-width: 388px;
      margin: 0 auto;
   }

	.box-less-horizontal-padding {
		padding: 30px;

      .box-bottom {
         margin-left: 0;
         margin-right: 0;
      }
	}

	.box-bottom {
		display: flex;
      flex-wrap: wrap;
		justify-content: space-around;
		
      gap: 10px 12px;

		margin-top: 20px;
      margin-left: -80px;
      margin-right: -80px;
		padding-top: 30px;
		border-top: 1px solid $text;

		.button {
			padding: 0;
		}
	}

   .login-choose-btn {
      
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
   }

   .participant-avatar,
   .login-choose-btn {
      cursor: pointer;

      color: $red;
      border: 1px solid $red;
      border-radius: 4px;
      padding: 12px 15px;

      margin-bottom: 16px;
      transition: all .4s;
      
      &:hover {
         color: $white;
         background: $red;
      }
   }
}

.auth-form {
	p { text-align: center; }
}

.auth-form-bottom {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	margin-top: 30px;
}

.not-logged-in-bottom {
	display: flex;
	align-items: center;
	gap: 20px;
	margin-top: auto;
}


@media ( max-width: 1100px ) {
   .not-logged-in {
      .box {
         width: auto;
         padding: 30px 60px;
      }

      .box-bottom {
         margin-left: 0;
         margin-right: 0;
      }
   }
}

@media ( max-width: 600px ) {
   .not-logged-in {
      .box {
         width: 100%;
         padding: 15px;
      }
   }
}