@import "styles/colors.scss";
@import "styles/mixins.scss";

.form-calendar-input {
    margin-bottom: 20px;

    label {
		display: flex;
		align-items: center;
		gap: 10px;
		font-weight: 500;
		margin-bottom: 3px;
	}
}

.form-calendar-input-box {
    cursor: pointer;

    display: flex;
	align-items: center;
	width: 100%;
	min-height: 48px;
	background: $white;
	border: 1px solid $gray3;
	border-radius: 4px;
	font-size: 1em;
	padding: 0 15px;

    svg {
        fill: $red;
        margin-left: auto;
    }
}

.form-calendar-input-popup {
    position: absolute;
    z-index: 1200;
    display: none;
}