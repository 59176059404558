@import "styles/colors.scss";

.search-form {
    display: flex;
    align-items: center;
    background: $white;

    border: 1px solid $gray3;
    border-radius: 4px;

    input {
        flex: auto;
        border: 0;
        border-radius: 4px;
        padding: 10px;
        height: 48px;
    }

    button {
        display: flex;
        border: 0;
        background: transparent;
        width: 48px;
        height: 48px;

        svg { margin: auto; fill: $red; }
    }
}