@import "styles/colors";
@import "styles/mixins";

.article-filters {
   @include box;
   margin-bottom: 30px;

   display: flex;
   gap: 20px;

   .form-date-range {
      margin: 0;
   }

   .form-input {
      flex: 1 1;
      margin: 0;
   }

   .form-dropdown {
      flex: 0 0 300px;
      margin: 0;
   }

   @media (max-width: 1330px ) {
      padding: 15px;
   }

   @media (max-width: 1100px ) {
      flex-direction: column;

      .form-dropdown {
         flex: 1 1;
      }
   }
}