@import "styles/colors.scss";
@import "styles/mixins.scss";

.modal {
	@include flex-centered;

	width: 100%;
	height: 100%;
	background: rgba( 0, 0, 0, .5 );

	position: fixed;
	z-index: 1200;
	left: 0;
	top: 0;

	padding: 15px;
}

.modal-box {
	padding: 30px;
	border-radius: 6px;
	background: $white;

   width: 733px;
   max-width: calc( 100vw - 30px );
}

.modal-box-head {
	display: flex;
	align-content: center;
	justify-content: space-between;
   margin-bottom: 30px;

   h3 {
      font-weight: 600;
      font-size: 1.375em;
      margin: 0;
   }

   button {
      cursor: pointer;
      border: 0;
      padding: 0;
      margin: 0;
      background: transparent;
}
}

.modal-box-body {
	@include has_scrollbar;
	overflow-y: auto;
	overflow-x: visible;

	max-height: calc( var(--vh, 1vh) * 100 - 90px - 150px);
}

.modal-box-buttons {
   margin-top: 30px;

	display: flex;
	align-items: center;
	justify-content: space-between;
}

@media ( max-width: 1100px ) {
   .modal-box { 
      width: 703px;
      padding: 15px;
   }

   .modal-box-head {
      margin-bottom: 20px;
   }

   .modal-box-body {
      max-height: calc( var(--vh, 1vh) * 100 - 165px );
   }

   .modal-box-buttons {
      margin-top: 16px;
   }
} 