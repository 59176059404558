@import "styles/colors.scss";

.info-lines {
    > div {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 0;
        border-bottom: 1px solid $line;

        > span {
            flex: 0 0 220px;
            max-width: 33%;
        }
    }

    @media (max-width: 600px ) {
      > div {
         display: block;

         span, strong {
            max-width: 100%;
            display: block;
         }
      }
    }
}