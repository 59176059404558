@import "styles/colors.scss";
@import "styles/mixins.scss";

.tabs {
    display: flex;
    flex-wrap: wrap;
    gap: 0 10px;
    
    button {

        @include flex-centered;
        gap: 18px;

        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;

        cursor: pointer;
        flex: 1 1 400px;
        min-height: 54px;
        border-radius: 2px 2px 0 0;
        border: 0;
        border-top: 6px solid transparent;
        background: rgba( $white, .6 );

        transition: all .2s;

        svg { 
            fill: $gray3;
        }

        &.active {
            border-top-color: $red;
            background: $white;
            border-radius: 6px 6px 0 0;

            svg { fill: $red;}
        }
    }
}

.tabs-content {
    border-radius: 0 0 6px 6px;
}