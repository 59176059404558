@import "styles/colors.scss";
@import "styles/mixins.scss";

.conversations-sidebar {
    display: flex;
    flex-direction: column;
    flex: 0 0 363px;

    padding: 30px 20px;
    background: $white;
    border-radius: 6px;

    @media (max-width: 1100px) {
      padding: 15px;
      margin-bottom: 10px;

      .conversations-sidebar-main {
         max-height: 149px;
      }
    }
}

.conversations-sidebar-head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 16px;

    h2 { margin: 0; }
}

.conversations-sidebar-main {
    @include has-scrollbar;

    overflow-y: auto;
    flex: auto;
    margin-top: 16px;

    .conversation-item:last-child { border: 0;}
}