@import "styles/colors.scss";

.guest-layout {
   display: flex;
   flex-direction: column;

   min-height: calc( var(--vh, 1vh) * 100 );

   header, footer {
      display: flex;
      align-items: center;
      gap: 30px;
      background: $white;
      
      padding: 30px 60px;

      > svg {
         max-width: 356px;
      }

      > a:first-of-type {
         margin-left: auto;
      }

      > a {
         color: $text;
         font-weight: bold;
         font-size: 1.24em;
      }
   }

   header > a:first-of-type {
      color: $red;
   }

   footer > a {
      font-weight: normal;
   }

   main {
      flex: auto;
   }

   header {
      @media (max-width: 1100px ) {
         padding-left: 15px;
         padding-right: 15px;
      }

      @media (max-width: 767px ) {
         flex-wrap: wrap;

         > svg, a {
            flex: 0 0 100%;
         }
      }
   }

   footer {
      @media (max-width: 1280px ) {
         flex-wrap: wrap;
         justify-content: center;
         padding-left: 15px;
         padding-right: 15px;

         > svg {
            flex: 0 0 100%;
            margin: auto;
         }

         a {
            flex: 0 0 100%;
            text-align: center;
         }
      }
   }
}