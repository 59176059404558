@import "styles/colors.scss"; 
@import "styles/mixins.scss"; 

.send-message-form-main {
    display: flex;
    align-items: flex-start;
    gap: 16px;

    margin-bottom: 10px;

    padding-top: 24px;
    border-top: 1px solid $line;

    .form-input {
        margin: 0;
        flex: auto;

        textarea { height: 48px; }
    }

    @media (max-width: 600px) {
      flex-wrap: wrap;
      margin-bottom: 0;
      padding-top: 15px;

      .form-input {
         flex: 1 1 100%;
      }

      .attach-files-btn {
         margin-left: auto;
      }
    }
}