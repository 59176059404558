@import "styles/colors.scss";
@import "styles/mixins.scss";

.sidebar {
    @include has_scrollbar;

    position: fixed;
    top: 0;
    left: -322px;
    z-index: 99;

    width: 322px;
    height: calc( var(--vh, 1vh ) * 100 );
    border-right: 1px solid $red;

    overflow: auto;
    background: $gray;

    padding: 30px 0 15px;

    transition: left .4s;

    nav {
        > span, a {
            display: block;
            font-weight: 500;
        }

        > span {
            text-transform: uppercase;
            margin: 16px 0;
            padding-left: 40px;
            padding-right: 10px;
            font-size: 12px;
            color: $gray3;
        }

        .item-count {
            background-color: $red;
            color: $white;
            border-radius: 4px;
            padding: 1px 3px;
          }

        a {
            position: relative;
            display: flex;
            align-items: center;
            padding-left: 30px;
            padding-right: 10px;

            gap: 6px;
            color: $text;

            span {
                flex: 0 0 46px;
                height: 46px;
                border-radius: 4px;

                display: flex;
                
                svg { 
                    margin: auto;
                    fill: $gray3;
                }
            }
        }

        a.active {
            color: $red;

            span {
                background: rgba( $red, .1 );
                svg { fill: $red }
            }

            &::before {
                content: "";
                display: block;

                width: 3px;
                height: 26px;
                border-radius: 0 5px 5px 0;
                background: $red;

                position: absolute;
                left: 0;
                top: calc( 50% - 13px );
            }
        }
    }

    &.open {
        left: 0;

        .sidebar-toggler {
            left: 322px;
            svg { transform: rotate( 0 );} 
        }
    }
}

.sidebar-logo {
    margin: 0 40px 26px;
}

.sidebar-toggler {
    cursor: pointer;
   
    position: fixed;
    left: 0;
    top: calc( var(--vh, 1vh ) * 50 - 30px ); 

    width: 30px;
    height: 60px;

    border: 1px solid $red;
    border-left: 0;
    border-radius: 0 6px 6px 0;
    background: $gray;

    transition: left .4s;

    svg { 
        fill: $red;
        transform: rotate( 180deg ); 
    }
}

@media ( max-width: 1110px ) {
   .sidebar {
      nav a {
         padding-left: 20px;
      }
   } 
}