@import "styles/colors.scss";

.day-week-table-top {
    display: flex;
    background: $gray2;

    padding-left: 72px;

    div {
        flex: 1 1;
        text-align: center;
        padding: 14px;

        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
    }
}

.day-week-table-box {
    position: relative;
    overflow: hidden;
    display: flex;

    border-bottom: 1px solid $gray2;
}

.day-week-table-time {
    flex: 0 0 72px;
    border-left: 1px solid $gray2;
    border-right: 1px solid $gray2;

    div {
        position: relative;
        text-align: right;
        height: 64px;
        padding: 8px;

        &::before,
        &::after {
            content: '';

            position: absolute;
            display: block;
            height: 1px;
            background: $gray2;

            width: 1920px;
        }

        &::before {
            top: 32px;
        }

        &::after {
            top: 64px;
        }
    }
}

.day-week-table-items {
    display: flex;
    flex: auto;

    div {
        position: relative;
        flex: 1 1;
        border-right: 1px solid $gray2;
    }

    .schedule-calendar-event {
        position: absolute;
        left: 0;
        right: 0;

        strong, span {
            display: block;
        }
    }
}