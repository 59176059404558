@import "styles/colors.scss";


.tile {
    display: block;
    position: relative;
    text-align: center;
    background: $white;
    border-radius: 6px;
    padding: 30px 30px 20px;

    h2 {
        margin-top: 35px;
        margin-bottom: 10px;
    }

    p {
       margin: 0;
       margin-bottom: 30px;
    }
}

.tile-bottom {
   margin: 0px -30px;
   width: 100%;
   position: absolute;
   bottom: -25px;
}