@import "styles/colors.scss";

.form-input {
	margin-bottom: 20px;

	label {
		display: flex;
		align-items: center;
		gap: 10px;
		font-weight: 500;
		margin-bottom: 3px;
	}

	input, textarea {
		width: 100%;
		border: 0;
		border-radius: 4px;
		padding: 10px 14px;

		&[placeholder] {
			color: $gray3;
		}
	}

	textarea {
		resize: none;
		height: 70px;
		padding: 16px 12px;
	}

	input[disabled], 
	textarea[disabled] {
		background: #f2f2f2;
	}

	.error {
		margin-top: 4px;
		font-size: 0.86em;
	}
}

.form-input-box {
	display: flex;

	width: 100%;
	min-height: 48px;
	border: 1px solid $gray3;
	border-radius: 4px;

	button {
		cursor: pointer;

		width: 48px;
		height: 48px;

		border: 0;
		background: 0;
		padding: 0;
	}
}

.form-input.with-error {
	.form-input-box {
		border-color: $red;
	}
}