@import "styles/colors.scss";

.specialists-box {
   &-search {
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid $line;

      .form-input {
         margin: 0;
      }
   }

   &-items {
      display: grid;
      grid-template-columns: repeat( 4, 1fr );
      gap: 30px;

      > div {

         display: flex;
         flex-direction: column;

         background: #FAFAFA;
         border-radius: 6px;
         padding: 20px;
         
         p { margin-top: 0; }

         .user-avatar {
            justify-content: center;

            img {
               max-width: 220px;
               max-height: 220px;
               
               width: 66%;
               height: auto;
               aspect-ratio: 1;
            }
         }

         > div:not(.user-avatar) {
            margin-top: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            .button.transparent {
               padding-left: 0;
               padding-right: 0;
            }
         }
      }

      @media (max-width: 1100px ) {
         grid-template-columns: repeat( 3, 1fr );
      }

      @media (max-width: 800px ) {
         gap: 16px;
         grid-template-columns: repeat( 2, 1fr );
       
         > div {
            padding: 10px;
         }
      }

      @media (max-width: 600px ) {
         grid-template-columns: 1fr;
      }
   }
}