.user-basic-info {

    .box-body {
        display: flex;
        align-items: flex-start;
        column-gap: 50px;
    }

    .user-basic-info-inputs {

        flex: auto;

        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .form-dropdown,
        .form-input {
            flex: 1 1 calc( 50% - 10px );
            margin: 0;
        }
    }

    @media (max-width: 1100px) {
      .box-body {
         flex-direction: column;
   
         .avatar-box {
            margin-top: 16px;
         }
      }
   }
   
   @media (max-width: 600px) {
      .user-basic-info-inputs {
         width: 100%;
         flex-direction: column;
   
         .form-dropdown,
         .form-input {
            flex: 1 1 100%;
         }
      }
   }
}