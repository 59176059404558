@import "styles/colors.scss";
@import "styles/mixins.scss";

.support-forms-widget {
   @include widget;
}

.support-forms-widget-popup {
   @include widget-popup;
   min-width: 360px;

   > div {
      cursor: pointer;

      &.active {
         color: $red;
         font-weight: bold;
      }
   }
}

@media (max-width: 600px) {
   .support-forms-widget {
      > span > span {
         display: none;
      }
   }   
}


@media (max-width: 500px) {
   .support-forms-widget-popup {
      padding: 10px;
      min-width: 300px;
   }
}
