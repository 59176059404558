@import "styles/colors.scss";
@import "styles/mixins.scss";

.form-dropdown {
	position: relative;
	margin-bottom: 20px;

	label {
		display: flex;
		align-items: center;
		gap: 10px;
		font-weight: 500;
		margin-bottom: 3px;
	}

	.error {
		margin-top: 4px;
		font-size: 0.86em;
	}
}

.form-dropdown.disabled {
	.form-dropdown-main {
		background: #f2f2f2;
	}
}

.form-dropdown.with-error {

	.form-dropdown-main {
		border-color: $red;
	}
}

.form-dropdown-main {
	display: flex;
	align-items: center;
	width: 100%;
	min-height: 48px;
	background: $white;
	border: 1px solid $gray3;
	border-radius: 4px;
	font-size: 1em;
	padding-left: 15px;

	input, > span {
		flex: auto;
	}  
	span {
		user-select: none;
		pointer-events: none;
	}

	input {
		padding: 0;
		border: 0;
		outline: 0;
		max-width: calc( 100% - 45px );
	}
}

.dropdown-main-icon {
	@include flex_centered;
	cursor: pointer;
	flex: 0 0 30px;
	height: 30px;
	margin-right: 6px;
	margin-left: auto;
}

.form-dropdown-options {
	@include has-scrollbar;
	overflow-y: auto;
	display: none;

	max-height: 126px;

	position: absolute;
	z-index: 1200;

	background: #fff;
	border: 1px solid $gray3;

	> div {
		cursor: pointer;
		padding: 10px 13px;

		&.active {
			font-weight: 500;
			background: $gray;
		}
	}
}