@import "styles/colors.scss";
@import "styles/mixins.scss";

.schedule-calendar {
    position: relative;
}

.schedule-calendar-loading {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50px;
}

.schedule-calendar-head {
    min-height: 48px;
    
    display: flex;
    align-items: center;
    justify-content: space-between;

    
    margin-bottom: 30px;
}

.schedule-calendar-head-main {
    display: flex;
    align-items: center;
    gap: 16px;

    h2 { margin: 0; }
}

.schedule-calendar-date-switch {
    display: flex;
    gap: 8px;

    button {
        @include flex-centered;
        cursor: pointer;

        flex: 0 0 48px;
        height: 48px;
        width: 48px;

        background: $red;
        border: 0;
        border-radius: 6px;

        svg { stroke: $white;}
    }
}

.schedule-calendar-mode-switch {
    display: flex;
    gap: 8px;   

    @media (max-width: 800px) {
      display: none;
    }
}

.schedule-calendar-event {
    cursor: pointer;
    background: $purple;
    color: $white;

    min-height: 24px;
    border-radius: 4px;
    padding: 4px 6px;

    &.break {
        pointer-events: none;
        font-size: 0.9em;
        background: $white;
        color: $text;
        margin: 0 5px;
    }
}

@media (max-width: 800px) {
   .tile-calendar-box > div {
      min-height: auto;
      aspect-ratio: 1.2;

      > span {
         padding: 0 4px;
      }
   }
}