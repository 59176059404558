.form-date-range{
    margin-bottom: 20px;

	label {
		display: flex;
		align-items: center;
		gap: 10px;
		font-weight: 500;
		margin-bottom: 3px;
	}
}

.form-date-range-inputs {
    display: flex;
    gap: 10px;

    .form-input {
        flex: 0 0 140px;
        max-width: 140px; 
        margin: 0;
    }
}