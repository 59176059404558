@import "styles/colors.scss";

.articles-items {
    display: grid;
    grid-template-columns: repeat( 4, 1fr );
    gap: 30px;

    @media (max-width: 1440px ) {
      grid-template-columns: repeat(3, 1fr);
   }
   
   @media (max-width: 990px ) {
      grid-template-columns: repeat(2, 1fr);
   }

   @media (max-width: 700px ) {
      grid-template-columns: 1fr;

      .article-tile {
         padding: 15px;
      }
   }
}

.articles-items + .pagination {
   margin-bottom: 20px;
}

.article-tile {

    display: flex;
    flex-direction: column;

    padding: 30px;
    background: $white;

    &-thumb {
        width: 100%;
        aspect-ratio: 1.45;
        background: $gray;
        margin-bottom: 20px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    h3 {
        margin-top: 0;
        margin-bottom: 10px;
    }
    
    p {
        margin-top: 0;
        margin-bottom: 10px;
    }

    .button {
        padding: 0;
        margin-left: auto;
        margin-top: auto;
    }
}

.article-tile-categories {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 8px;

    div {
        color: $red;
        border: 1px solid $red;
        border-radius: 2px;
        padding: 2px 6px;
    }
}