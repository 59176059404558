.user-avatar {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
        width: 46px;
        height: 46px;
        min-width: 46px;
        object-fit: cover;
        border-radius: 50%;
    }
}