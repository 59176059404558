@import "styles/colors.scss";

.conversation-box-head {

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 24px;
    border-bottom: 1px solid $line;

    .red-icon {
        fill: $red;
    }

    .user-avatar span {
        font-family: "Dosis", sans-serif;
	    font-size: 1.375em;
        font-weight: 600;
    }
}


.widget-popup {
    position: absolute;
    margin-left: -150px;

    display: flex;
    flex-direction: column;
    gap: 20px;

    box-shadow: 0px 4px 10.6px 0px #4140424D;

    width: 201px;
    padding: 16px;
    background: $white;
    border-radius: 6px;

    a {
        display: flex;
        align-items: center;
        gap: 10px;

        font-weight: 500;
        color: $text;

        svg { fill: $gray3; }
    }

    a:hover {
        color: $red;
        svg { fill: $red; }
    }
}