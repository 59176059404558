.main-layout.sidebar-open {
    .main-layout-box {
        padding-left: 322px;
    }
}

.main-layout-box {
    display: flex;
    flex-direction: column;
    min-height: calc( var(--vh, 1vh ) * 100 );

    transition: padding .4s;
    padding-left: 0;

    main {

        max-width: 1920px;

        display: flex;
        flex-direction: column;

        flex: auto;
        padding: 30px 44px 0;
    }
}

@media (max-width: 1330px ) {
   .main-layout {
      &.sidebar-open .main-layout-box {
         padding-left: 0;
      }

      main {
         padding: 20px 15px 0;
      }
   }
}