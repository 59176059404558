@import "styles/colors.scss";

.checkbox {
	cursor: pointer;
	
	display: flex;
	align-items: center;
	gap: 8px;

	margin-bottom: 16px;
}

.checkbox.checked {
	.checkbox-box {
		background: $red;
		border-color: $red;
	}
}

.checkbox.checked.disabled {
	.checkbox-box {
		background: $gray3;
		border-color: $gray3;
	}
}

.checkbox-box {

	display: flex;
	align-items: center;
	justify-content: center;

	width: 24px;
	min-width: 24px;
	height: 24px;

	border: 1px solid $gray3;

	border-radius: 4px;

	background: $white;
	transition: background .2s;
}

.checkbox-label {
	user-select: none;
}