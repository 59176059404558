@import "styles/colors.scss";
@import "styles/mixins.scss";


.user-widget {
   @include widget;

   > span {
      img {
         width: 49px;
         height: 49px;
         border-radius: 50%;
         object-fit: cover;

         @media (max-width: 500px) {
            width: 35px;
            height: 35px;
         }
      }
   }
}

.user-widget-popup {
   @include widget-popup;

    min-width: 201px;

    a {
        display: flex;
        align-items: center;
        gap: 10px;

        font-weight: 500;
        color: $text;

        svg { fill: $gray3; }
    }

    a:hover {
        color: $red;
        svg { fill: $red; }
    }

    @media (max-width: 1330px) {
      left: auto !important;
      right: 0 !important;
   }

    @media (max-width: 500px) {
      padding: 10px;
      min-width: 180px;
    }
}