.home-tiles {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   gap: 20px;
   padding-bottom: 30px;

   @media (max-width: 1100px) {
      .tile {
         padding: 20px 15px;
      }

      .tile-bottom {
         margin-left: -15px;
         margin-right: -15px;
      }
   }

   @media (max-width: 800px) {
      grid-template-columns: 1fr;
      gap: 40px;
   }
}