@import "styles/colors.scss";

.guest-page-header {
   display: flex;
   flex-direction: column;
   align-items: flex-start;

   height: 318px;

   background-position: 50%;
   background-size: cover;

   h1 {
      background: $red;
      color: $white;

      border-radius: 0 20px 20px 0;
      padding: 20px 30px 20px 90px;
      margin: auto 0;
   }
}

.guest-page-header + div {
   max-width: 1800px;
   margin: 0 auto;

   position: relative;
   top: -76px;
}

@media (max-width: 990px ) {
   .guest-page-header {
      h1 {
         padding-left: 15px;
      }
   }
}