.form-radio-group {
    margin-bottom: 20px;

    > label {
		display: flex;
		align-items: center;
		gap: 10px;
		font-weight: 500;
		margin-bottom: 10px;
	}
}

.form-radio-group-items {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    .form-radio {
        flex-grow: 1;
    }

    @media (max-width: 600px ) {
      flex-direction: column;
   }
}