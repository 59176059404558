@import "styles/colors.scss";

.page-header {
    margin-bottom: 28px;

    h1 { margin: 0; }
}

.page-header-top {

    display: flex;
    align-items: center;
    gap: 12px;

    padding-bottom: 6px;
    margin-bottom: 6px;
    border-bottom: 1px solid $line;
}