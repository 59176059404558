@import "styles/colors.scss";

.support-forms {
   display: flex;
   flex-wrap: wrap;
   gap: 30px;

   > a {
      cursor: pointer;

      display: flex;
      align-items: center;
      gap: 10px;
      padding: 30px;

      color: $text;
      background: $white;
      font-weight: 600;
      border-radius: 6px;
      min-height: 160px;

      width: calc(( 100% - 60px ) / 3);

      img {
         width: 48px;
         height: 48px;
         object-fit: cover;
      }
   }

   @media (max-width: 1330px) {
      gap: 20px;
      > a { width: calc(( 100% - 40px ) / 3 );}
   }

   @media (max-width: 1110px) {
      gap: 16px;
      > a { 
         width: auto;
         padding: 15px;
         flex: 1 1 320px; 
      }
   }
}

.nothing {
   margin: 40px 0px;
   text-align: center;
}