@import "styles/colors.scss";

.tile-calendar {
    border-bottom: 1px solid $gray2;
}

.tile-calendar-days {
    display: flex;
    background: $gray2;

    div {
        flex: 1 1;
        text-align: center;
        padding: 14px;

        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
    }
}

.tile-calendar-box {
    display: grid;
    grid-template-columns: repeat( 7, 1fr );

    > div {
        display: flex;
        flex-direction: column;
        gap: 3px;

        border: 1px solid $gray2;
        border-right: 0;
        border-bottom: 0;

        min-height: 122px;

        > span {
            padding-left: 8px;
            padding-top: 8px;
            padding-bottom: 16px;
            text-align: right;
        }

        &:nth-child(7n) {
            border-right: 1px solid $gray2;
        }
    }

    .schedule-calendar-event {
        font-size: 14px;
    }
}