@import "styles/colors.scss";
@import "styles/mixins.scss";

.participants-widget {
   @include widget;

   .participant-avatar {
      img {
         width: 36px;
         min-width: 36px;
         height: 36px;
      }
   }

   > span .participant-avatar {
      font-weight: bold;
   }
}

.participants-widget-popup {
   
   @include widget-popup;

   gap: 10px;

   min-width: 322px;
   border-top: 1px solid $line;
   padding-top: 14px;

   .participant-avatar {
      cursor: pointer;

      &.active {
         color: $red;
         font-weight: bold;
      }
   }
}

@media (max-width: 600px) {
   .participants-widget {
      > span > span {
         display: none;
      }
   }   
}


@media (max-width: 500px) {
   .participants-widget {
      > span {
         .participant-avatar img { display: none; }
      }
   }   

   .participants-widget-popup {
      padding: 10px;
      min-width: 260px;
   }
}