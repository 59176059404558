@import "styles/colors.scss";
@import "styles/mixins.scss";

.svg-btn{
	@include flex_centered;
	cursor: pointer;

	background: transparent;
	padding: 0;
	margin: 0;
	border: 0;

	width: 28px;
	height: 28px;
	flex: 0 0 28px;

	&[disabled] { opacity: 0.3; }

	svg {
		fill: $red;
	}
}