@import "styles/colors.scss";

@mixin box {
	background: $white;
	padding: 30px;
	border-radius: 6px;
}

@mixin flex-centered {
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin has_scrollbar {
	scrollbar-width: thin;
	scrollbar-color: $red $tooltip_bg;

	&::-webkit-scrollbar {
		width: "4px";
	}
		
	&::-webkit-scrollbar-track {
		background: $tooltip_bg;
	}
		
	&::-webkit-scrollbar-thumb {
		background-color: $red; 
		outline: none;
	}
}

@mixin widget {
   position: relative;

   > span {
      cursor: pointer;
      gap: 8px;
      display: flex;
      align-items: center;
      
      > svg {
         fill: $red;
         transition: transform .2s;
      }
   }
}

@mixin widget-popup {
   position: absolute;
   z-index: 10;

   display: flex;
   flex-direction: column;
   gap: 20px;

   box-shadow: 0px 4px 10.6px 0px #4140424D;

   padding: 16px;
   background: $white;
   border-radius: 6px;
}

@mixin circle( $size, $bg: transparent, $centered: false ) {
	border-radius: 50%;
	width: $size;
	min-width: $size;
	height: $size;

	background: $bg;

	@if $centered {
		@include flex_centered;
		padding: 0;
		margin: 0;
		border: 0;
	}
}
