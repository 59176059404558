@import "styles/colors.scss";
@import "styles/mixins.scss";

.toast {
    position: fixed;
    left: calc(50% - 140px );
	z-index: 1205;

	display: block;
    width: 280px;

	border-radius: 4px;
	color: #fff;
	padding: 10px 24px 10px 14px;

	font-weight: 600;
    transition: top .5s;

	&.success {
		background: $green;
	}

	&.error {
		background: #D30000;
	}

	a {
		cursor: pointer;
		position: absolute;
		top: 5px;
		right: 5px;
		color: #fff;
	}
}