@import "styles/colors.scss";

.top-bar {
    
   display: flex;
   align-items: center;

   background: $white;
   min-height: 85px;
   padding: 18px 45px;
   border-bottom: 1px solid $line;

   .user-widget {
      margin-left: auto;
   }

    @media (max-width: 1330px) {
      padding: 10px 15px;
      min-height: 60px;
   }
}


.top-widgets {
   position: relative;
   display: flex;
   flex-direction: row;
   margin-left: auto;
   align-items: center;

   .separator {
       margin: 0px 20px;
       height: 20px;
       width: 1px;
       background: $gray3;

       @media (max-width: 600px) {
         margin: 0 12px;
      }
   }

   .icon-gray {
       fill: $gray3;
   }

   .icon-red {        
       fill: $red;
   }

   .icon-in-link {
       margin-bottom: -5px;
   }

   .new-messages-count {
       position: absolute;
       line-height: 18px;
       font-size: 14px;
       margin-top: -10px;
       margin-left: 10px;
       background: $white;
       border: 1px $gray3 solid;
       border-radius: 20px;
       width: 20px;
       height: 20px;
       text-align: center;
   }
}