@import "styles/colors.scss";

.box {
    padding: 30px;
    margin-bottom: 20px;
    border-radius: 6px;
    background: $white;

    @media (max-width: 1330px ) {
      padding: 15px;
    }
}

.box-head {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    h2 { margin: 0; }
}