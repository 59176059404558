@import "styles/colors.scss";

.button {
	cursor: pointer;
	font-family: "Dosis", sans-serif;
	
	font-size: 1em;
	font-weight: 700;
	
	text-transform: uppercase;
	display: inline-block;
	text-align: center;

	border: 0;
	border-radius: 4px;

	padding: 14.5px 20px;

	background: $red;
	color: $white;
	
	transition: background .4s, color .4s, text-decoration .4s;

	&:hover {
		background: $red + a0;
	}
}

.button.gray {
	background: $gray2;
	color: $text;
}

.button.transparent {
	background: transparent;
	color: $red;

	&.purple {
		color: $purple;
	}

	&:hover {
		text-decoration: underline;
	}
}

.button.outline {
   background: transparent;
	color: $red;
   border: 1px solid $red;

   &:hover {
      background: $red;
      color: $white;
   }
}

.button[disabled] {
	opacity: 0.5;

	&:hover {
		background: $red;
	}
}