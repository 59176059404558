@import "styles/colors.scss";

.add-edit-layout {
    display: flex;
    flex-direction: column;
    flex: auto;

    .bottom-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;

        background: $white;

        margin-top: auto;
        margin-left: -44px;
        margin-right: -44px;
        padding: 20px 44px;

        @media (max-width: 1330px ) {
         margin-left: -15px;
         margin-right: -15px;
         padding: 10px 15px;
      }
    }
}