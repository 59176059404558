$white: #fff;
$black: #000;

$text: #414042;

$purple: #4434A4;
$red: #A4344E;

$tooltip_bg: #FFF9FB;

$gray: #FAFAFA;
$gray2: #EEEEEE;
$gray3:#737373;

$line: #B4B4B4;
$green: #00851D;