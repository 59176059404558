@import "styles/colors.scss";

.form-radio {
    cursor: pointer;

    display: flex;
    align-items: center;
    gap: 8px;
}

.form-radio-btn {
    position: relative;

    flex: 0 0 24px;
    height: 24px;
    border: 1px solid $red;
    border-radius: 50%;

    &::before {
        content: '';
        position: absolute;

        display: block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        top: calc(50% - 6px);
        right: calc(50% - 6px);
    }
}

.form-radio.disabled {
    cursor: unset;

    .form-radio-btn {
        border-color: $gray3;
    }

    &.checked {
        .form-radio-btn {
            border-color: $gray3;

            &::before {
                background: $gray3;
            } 
        }
    }
}

.form-radio.checked {
    .form-radio-btn {
        border: 2px solid $red;

        &::before {
            background: $red;
        }
    }
}