@import "styles/colors.scss";

.form-attachment {
    position: relative;

    label {
		display: flex;
		align-items: center;
		gap: 10px;
		font-weight: 500;
	 	margin-bottom: 3px;
	}

    .file-lines {
       margin-top: 10px;
    }
}

.form-attachment-box {
    cursor: pointer;
    position: relative;
    color: $red;
    font-size: 1.375em;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border: 1px dashed $red;
    border-radius: 10px;

    gap: 20px;
    min-height: 220px;

    > div { text-align: center; }

    .button {
        font-size: 0.727em;
    }
}