@import "styles/colors.scss";
@import "styles/mixins.scss";


.table {
   width: 100%;
   border-collapse: collapse;

   thead {
      background: $gray2;

      tr {
         border-bottom: 1px solid $white;
      }

      .form-date-range,
      .form-dropdown,
      .form-input { margin: 0px; }
      
      th {
         font-weight: normal;
         text-align: left;
         padding: 16px 10px;

         > span {
            font-weight: 700; 
            font-size: 0.75em;
            text-transform: uppercase;
            user-select: none;
            
            justify-content: space-between;
            align-items: center;
            gap: 6px;

            div {
               display: flex;
               flex-direction: column;
               gap: 3px;
            }
         }
      }
   }

   tbody {
      background: $white;

      tr {
         border-bottom: 1px solid $gray2;
      }

      td {
         padding: 10px;
      }
   }

   tfoot {
      td { padding: 10px; }
   }
}

.table-td-column {
   display: none;
}

.table-actions {
   @include flex_centered;
   gap: 8px;
}

@media (max-width: 1100px) {
   .table {
      thead { display: none; }

      .table-td-column {
         display: block;
         font-weight: bold;
         margin-bottom: 6px;
      }

      tbody, tbody tr, tbody td {
         display: block;
      }

      tbody td {
         padding-left: 0;
         padding-right: 0;
      }

      .table-actions,
      .activity-status {
         justify-content: flex-start;
      }
   }
}