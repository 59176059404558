@import "styles/colors.scss";

.conversation-item {

    cursor: pointer;

    display: flex;
    align-items: center;
    gap: 10px;

    padding: 14px 10px;
    border-bottom: 1px solid $line;

    &.active {
        background: #f2f2f2;
    }

    img {
        width: 46px;
        height: 46px;
        object-fit: cover;
        border-radius: 50%;
    }
}