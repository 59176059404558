.rating-box {
   display: flex;
   align-items: center;
   gap: 3px;

   user-select: none;
}

.rating-box.big-stars {
   gap: 14px;
   svg { width: 53px; }
}