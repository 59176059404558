@import "styles/colors.scss";
@import "styles/mixins.scss";

.avatar-box {
	width: 200px;

	input {
		width: 0;
		height: 0;
		display: none;
	}
}

.avatar-box-preview {
	cursor: pointer;
	width: 200px;
	aspect-ratio: 1;
	border-radius: 50%;

	background-position: 50%;
	background-size: cover;
	background-repeat: no-repeat;

	margin-bottom: 8px;
}

.avatar-box-buttons {
	@include flex_centered;
	gap: 6px;
}