@import "styles/colors.scss";
@import "styles/mixins.scss";

.conversation-box {

    display: flex;
    flex-direction: column;
    padding: 30px;

    flex: auto;
    background: $white;
    border-radius: 6px;
    
    @media (max-width: 1100px ) {
      padding: 15px;
      margin-bottom: 10px;

      .conversation-box-messages {
         height: 80vh;
         padding: 15px 0;
      }
    }
}

.conversation-box-messages {
    @include has-scrollbar;

    flex: auto;
    padding: 30px 0;
    overflow-y: auto;
}