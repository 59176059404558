@import "styles/mixins.scss";

.status-box {
    display: flex;
    align-items: center;
    gap: 5px;

    span {
        @include circle( 14px );
    }
}

.status-box.confirmed span,
.status-box.accepted span,
.status-box.reported span { background: #00851D; }

.status-box.rejected span,
.status-box.not-reported span { background: #D30000; }

.status-box.canceled span { background: #990049; }

.status-box.upcoming span,
.status-box.waiting span { background: #F79100; }

.status-box.completed span { background: #4434A4; }