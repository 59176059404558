@import "styles/colors.scss";

* {
	box-sizing: border-box !important;
}

html, body { margin: 0; }

body {
	background: $gray2;
   color: $text;
   font-size: 16px;
   font-family: "Roboto", sans-serif;

   @media (max-width: 1440px) {
      font-size: 14px;
   }
    
   @media (max-width: 1110px) {
      font-size: 13px;
   } 
}

a {
	text-decoration: none;
}

input, textarea, select {
	color: $text;
    font-family: 'Roboto', sans-serif;
	font-size: 1em;
}

img, svg {
	max-width: 100%;
	height: auto;
}

h1, h2, h3, h4, h5 {
	font-family: "Dosis", sans-serif;
} 

h1 {
	font-weight: 700;
	font-size: 1.875em;
}

h2 {
	font-weight: 600;
	font-size: 1.375em;
}

.error {
	color: $red;
	font-weight: 500;
}